/** * 合作案例 */
<template>
  <v-app class="section-box">
    <v-banner single-line height="300" class="banner">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/bannerWmyCase400.jpg"
        height="100%"
        :transition="false"
        :class="imgLoad ? 'v-image__image--animation' : ''"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-banner>
   
    <div class="warp">
      <p >全景漫游</p>
      <div class="search_box">  
        <el-input
          placeholder="请输入内容"
          v-model="form.vrName">
        </el-input><i @click="search" class="el-icon-search"></i>
      </div>
    </div>
    <div style="margin:0 auto">
     <div style="width: 1120px;background-color: rgb(255, 255, 255);margin-left: -17px;">
       <v-tabs
         centered
         fixed-tabs
         hide-slider
         class="new-tabs-two"
         v-model="tab"
       >
         <v-tab 
           :value="v.id"
           v-for="(v,k) in typeMenu"
           :key="k"
           @click="clickLevelTwo(v)"
         >{{ v.typeName }}</v-tab>
       </v-tabs>
     </div>
     <div class="div-tabs" style="min-height: 387px;">
            <div  class="new-tabs-items-two" style="background-color:#ffffff;">
              <v-row>
                <v-col 
                  v-for="(v, k) in list"
                  :key="k" cols="3"
                >
                  <v-container fluid>
                  <v-card
                    class="mx-auto item-card"
                    max-width="280"
                    max-height="351"
                    @click="goCaseDetail(v)"
                  >
                    <v-img
                      max-height="260"
                      class="tran-sec"
                      :src="v.picUrl"
                      :aspect-ratio="9 / 16"
                    >
                    </v-img>
                    <div class="shareBox" >
                       <div class="share" @mouseover='currentShare=k' @mouseleave="currentShare=''">
                          <img src='../../../public/img/icons/wmyHome/share.png' width="12px" />
                       </div>
                       <!-- v-show='currentShare===k' -->
                       <section class="img_box" style="text-align: center" v-show='currentShare===k'>
                         <span>
                           <vue-qr
                             :text="v.fileUrl?v.fileUrl:''"
                             :size="150"
                             :margin="10"
                             :correctLevel="1"
                             background-color="#f5f7fa"
                             class="temp-code"
                           ></vue-qr>
                         </span>
                       </section>
                    </div>
                    <div class="case-box">
                      <div
                        style="
                          font-size: 14px;
                          font-weight: 500;
                          color: #333333;
                          line-height: 20px;
                        "
                        class="mt-8"
                      >
                        {{ v.vrName}}
                      </div>
                      <div
                        style="
                          font-size: 12px;
                          font-weight: 400;
                          color: #999999;
                          line-height: 17px;
                        "
                        class="mt-2"
                      >
                        {{ v.authorName }}
                        <span style="float: right">
                          <i class="el-icon-view">{{ v.openCount }}</i>
                          <img
                            src="../../../public/img/icons/wmyHome/dianzhan.png"
                            width="12px"
                            class="ml-2"
                          />{{ v.likesCount }}
                        </span>
                      </div>
                    </div>
                  </v-card>
                  </v-container>
                </v-col>
              </v-row>
            </div>
     </div>
    </div>

    <!-- 分页 -->
    <pagination
      :total='pageTotal'
      :page.sync='pageNum'
      :limit.sync='pageSize'
      @pagination='get_list()'
    ></pagination>

  </v-app>
</template>

<script>
import VueQr from "vue-qr";   
import Pagination from '../../components/wmy/solutions/pagination.vue'   
const DEFAULT_FORM = {
  type: 0,
  typeId: "",
  vrName: "",
};
export default {
  name: "Case",
  components: { VueQr,Pagination },
  data() {
    return {
      tab: null,
      imgLoad: false,
      form: Object.assign({}, DEFAULT_FORM),
      typeMenu:[], //分类列表
      list:[], //案例列表
      isshow:false, //是否展示二维码
      pageTotal: 0,
      pageNum: 1,
      pageSize: 20,
      currentShare:''
    };
  },
  mounted() {
    this.get_list()
    this.getTypeMenu()
  },
  methods: {
    // on_page(val) {
    //   this.pageNum = val;
    //   this.get_list();
    // },
    search(){
      this.pageNum = 1;
      this.get_list();
    },
    //分类下拉菜单
    getTypeMenu(){    
        let url =process.env.VUE_APP_NODE_ENV === "development"
          ? process.env.VUE_APP_API_DEV +'/vrview/api/caseType/getList'
          : process.env.VUE_APP_NODE_ENV === "test"
          ? "https://test.iwmy.igame123.com/apiProxy/vrview/api/caseType/getList"
          : "https://i.wmy.ophyer.com/apiProxy/vrview/api/caseType/getList";
        this.$axios.get(url).then((res) => {
           
           this.typeMenu = res.data.data;
           let data = {
             id:'',
             typeName:'全部'
           }
           this.typeMenu.unshift(data)
        });
    },
    get_list() {
      let url =
        process.env.VUE_APP_NODE_ENV === "development"
          ? process.env.VUE_APP_API_DEV +'/vrview/api/boutiqueCase/officalpage'
          : process.env.VUE_APP_NODE_ENV === "test"
          ? "https://test.iwmy.igame123.com/apiProxy/vrview/api/boutiqueCase/officalpage"
          : "https://i.wmy.ophyer.com/apiProxy/vrview/api/boutiqueCase/officalpage";
      console.log(url, "url");
      let param = this.form
      param.pageNum = this.pageNum
      param.pageSize = this.pageSize
      //let url = process.env.VUE_APP_API_DEV +'/vrview/api/boutiqueCase/officalpage'
      this.$axios.get(url, { params: param }).then((res) => {
        this.list = res.data.data.records;

        for(let i=0;i<this.list.length;i++){
          let num = parseInt(this.list[i].likesCount)
          console.log(num,'this.list[i].likesCount')
          if(eval(10000)< num && eval(99999)>num){
             num = parseFloat((num/10000).toFixed(1))   
             this.list[i].likesCount = num + 'w'
          }
          if( num>eval(100000)){
             num = parseFloat((num/10000).toFixed(1))
             this.list[i].likesCount = num + 'w'
          }
        }
        this.pageTotal = res.data.data.total
      });
    },
    goCaseDetail(item) {
        window.open(item.fileUrl, "_blank"); 
    },

    clickLevelTwo(k) {
      this.form.typeId = k.id
      this.pageNum = 1
      this.get_list()
    },
  },
};
</script>

<style lang="scss" scoped>
.section-box {
  // background: #f5f8fd;
  position: relative;
  z-index: 6;
}
.banner {
  position: relative;
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
  .banner-text {
    text-align: center;
    margin-top: 20px;
  }
}
.new-tabs-two {
  flex: unset;
}
::v-deep .new-tabs-two .v-tab{
  width: auto !important;
  padding: 0 40px !important;
}
::v-deep .v-tab{
  max-width: none !important;
}
.g-tabs {
  height: 65px;
  background: #fff;
  padding-top: 10px;
  flex: unset;
  ::v-deep .v-tab {
    font-size: 22px;
    padding: 0 25px;
    &::before {
      background: unset !important;
    }
  }
  ::v-deep.v-ripple__container {
    display: none !important;
  }
}
::v-deep .new-tabs-items-two .v-window-item {
  margin: 15px 20px 0 !important;
  height: 358px;
}
::v-deep .new-tabs-items-two .v-window__container {
  background:#fff;
  padding-top: 15px !important;
}
::v-deep .new-tabs-items-two .item-card::after {
  bottom: 34px !important;
}

.div-tabs {
  min-height: 520px !important;
  position: relative;
  z-index: 7;
}
.shareBox{
  position: relative;
  .share{
    width: 20px;
    height: 20px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    text-align: center;
    padding-top: 3px;
    /* top: -300px; */
    position: absolute;
    top: -254px;
    left: 207px;
  }
  .img_box{
    position: absolute;
    top: -196px;
    left: 42px;
  }
}
::v-deep .new-tabs-two .v-tabs-bar__content{
  margin-left: 14px !important;
  -webkit-justify-content:normal !important;
  justify-content: normal !important;
  //margin: 0px !important;
}
::v-deep .new-tabs-two .v-tabs-bar{
  height: auto !important;
}

.new-tabs-two-new{
  max-width: unset !important;
  // margin-bottom: 10px;
  .v-tabs-bar {
    min-height: 48px !important;
    // height: unset;
    // padding: 20px 0;
  }
  .v-tabs-bar__content {
    flex: none !important;
  }
  .v-slide-group__wrapper {
    display: flex !important;
    flex-wrap: wrap !important;
    overflow: unset;
  }
  .v-tabs-bar__content {
    // width: 1130px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex: unset !important;
    // justify-content: center !important;
    padding: 10px 0;
  }
  .v-tab {
    width: 140px !important;
    height: 40px;
    font-size: 16px !important;
    color: #666 !important;
    font-weight: 400;
    padding: 0 !important;
    flex: unset !important;
    margin-right: unset !important;
    margin-left: unset !important;
    position: relative;
    flex: none !important;
    &::before {
      background: unset !important;
    }
    &::after {
      content: "";
      width: 1px;
      height: 19px;
      background: #e0e0e0;
      position: absolute;
      right: 0%;
      top: 25%;
      transform: translate(50%, 25%);
    }
    &:last-child::after {
      display: none;
    }
    &:nth-of-type(8)::after {
      display: none;
    }
  }
  .v-tab--active {
    border-radius: 25px;
    color: #0084FF !important;
    &::before {
      opacity: unset;
    }
  }
}
::v-deep .search_box{
  position: relative;
  left: 29px;
  display: flex;
  float: right;
  top: 10px;
  .el-input__inner{
     width: 253px;
     height: 40px;
     border-radius: 20px;
     border: 1px solid #E8E9EC;
  }
}

.warp{
   background-color:#ffffff; 
   display: flex; 
   width: 1050px; 
   margin: 10px auto;
   p{
     font-size: 20px;
     color: #303234;
     font-weight: 400;
     width: 1050px;
     margin: 0 auto;
     height: 45px;
     line-height: 60px;
   }
   .el-icon-search{
     position: absolute;
     top: 13px;
     left: 219px;
     width: 20px;
     font-size: 16px;
   }
}
</style>